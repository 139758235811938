import React from "react"
import "../css/global.css"
import Navbar from "../components/navbar"
import SEO from "../components/SEO"
import Ert from "../components/ert"
import { graphql, Link } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"
import { siteMetadata } from "../../gatsby-config"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

export default function BlogPost({
  data,
  pageContext,
  location
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt, id } = markdownRemark
  const { next, previous, words } = pageContext
  const publishedDate = {
    datePublished: frontmatter.datePublished
  }
  let disqusConfig = {
    url: siteMetadata.url + location.pathname,
    identifier: id,
    title: frontmatter.title,
  }  
  return (
    <main>
      <SEO
        title={frontmatter.title}
        pathname={location.pathname}
        banner={frontmatter.banner ? frontmatter.banner : null}
        desc={excerpt}
        node={publishedDate}
        article
      />

      <Navbar></Navbar>

      <div className="lg:max-w-screen-md mx-auto p-6 lg:pt-32 pt-24">
        <h1 className="lg:text-center text-5xl tracking-tighter leading-none">
          {frontmatter.title}
        </h1>
        <p className="lg:text-center mt-3">
          <span className="text-pink uppercase font-semibold tracking-tighter">
            {frontmatter.topic}
          </span>{" "}
          · {frontmatter.date} · <Ert words={words}></Ert>
        </p>

        <div className="blog-post-container mt-8">
          <div className="blog-post">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
          {frontmatter.tags ? (
            <div className="mb-6">
              {frontmatter.tags.map(tag => (
                <span
                  key={tag}
                  className="inline-block rounded-full font-semibold text-gray mr-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          ) : null}
          <div className="">
            <p className="text-sm uppercase leading-none flex flex-row items-center mb-0 text-gray">
              Share{" "}
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://fahimmurshed.com/blog/${frontmatter.slug}`}
                className="no-link-effect p-2 border border-black rounded hover:border-pink mx-2 ml-4 transition-all duration-300 hover:-translate-y-1 transform"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 2H18V6H16C14.8954 6 14 6.89543 14 8V10H18L16.9565 14H13.8261V22H9.65217V14H6V10.0396H10V7C10 4.23858 12.2386 2 15 2Z"
                    stroke="#000"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </a>
              <a
                href={`https://twitter.com/intent/tweet/?text=${frontmatter.title}&url=https://fahimmurshed.com/blog/${frontmatter.slug}%2F&via=fahimmurshedo_o`}
                className="no-link-effect p-2 border border-black rounded hover:border-pink mx-2 transition-all duration-300 hover:-translate-y-1 transform"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.288 21C15.832 21 19.96 14.4544 19.96 8.78772C19.96 8.60357 19.96 8.41943 19.952 8.23528C20.752 7.62425 21.448 6.87092 22 6.01715C21.248 6.36033 20.456 6.5947 19.64 6.69514C20.496 6.15945 21.136 5.31404 21.44 4.31798C20.632 4.8202 19.752 5.17175 18.832 5.3559C17.28 3.62324 14.68 3.53954 13.024 5.17175C11.96 6.21804 11.504 7.78328 11.84 9.2732C8.552 9.09742 5.472 7.46521 3.392 4.78671C2.304 6.74537 2.856 9.25646 4.664 10.512C4.008 10.4953 3.376 10.3111 2.8 9.9763C2.8 9.99305 2.8 10.0098 2.8 10.0265C2.8 12.0689 4.176 13.8266 6.096 14.2368C5.488 14.4126 4.856 14.4377 4.24 14.3121C4.776 16.0615 6.32 17.2585 8.072 17.292C6.616 18.4889 4.824 19.1334 2.976 19.1334C2.648 19.1334 2.32 19.1083 2 19.0748C3.88 20.3387 6.056 21 8.288 21Z"
                    stroke="#000"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=https://fahimmurshed.com/blog/${frontmatter.slug}&title=${frontmatter.title}&source=${frontmatter.title}`}
                className="no-link-effect p-2 border border-black rounded hover:border-pink mx-2 transition-all duration-300 hover:-translate-y-1 transform"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="4"
                    cy="4"
                    r="2"
                    stroke="#000"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></circle>
                  <rect
                    x="2"
                    y="9"
                    width="4"
                    height="13"
                    stroke="#000"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></rect>
                  <path
                    d="M10 22H14V15C14 13.8954 14.8954 13 16 13C17.1046 13 18 13.8954 18 15V22H22V15C22 11.6863 19.3137 9 16 9C12.6863 9 10 11.6863 10 15V22Z"
                    stroke="#000"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </a>
            </p>
          </div>
          <div className="pagination mt-10 mb-16 grid grid-cols-2 gap-4">
            <div>
              {previous && (
                <Link
                  to={"/blog/" + previous.frontmatter.slug}
                  rel="prev"
                  className="no-link-effect hover:text-pink transition-all duration-300"
                >
                  <p className="uppercase text-gray text-sm leading-snug">
                    Previous Post
                  </p>
                  <p className="text-md lg:text-lg font-semibold leading-snug">
                    {previous.frontmatter.title}
                  </p>
                </Link>
              )}
            </div>

            <div className="text-right">
              {next && (
                <Link
                  to={"/blog/" + next.frontmatter.slug}
                  rel="next"
                  className="no-link-effect hover:text-pink transition-all duration-300"
                >
                  <p className="uppercase text-gray text-sm leading-snug">
                    Next Post
                  </p>
                  <p className="text-md lg:text-lg font-semibold leading-snug">
                    {next.frontmatter.title}
                  </p>
                </Link>
              )}
            </div>
          </div>
          <div className="mb-10">
            <Disqus config={disqusConfig} />
          </div>
        </div>
      </div>
    </main>
  )
}
export const pageQuery = graphql`
         query($slug: String!) {
           markdownRemark(frontmatter: { slug: { eq: $slug } }) {
             html
             id
             excerpt(pruneLength: 160)
             frontmatter {
               date(formatString: "MMMM DD, YYYY")
               datePublished: date(formatString: "YYYY-MM-DD")
               slug
               title
               topic
               tags
               banner
             }
           }
         }
       `
