/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.org/docs/gatsby-config/
 */

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: "Fahim Murshed",
    headline: "Fahim Murshed",
    titleTemplate: "%s | Fahim Murshed",
    author: "Fahim Murshed",
    description:
      "Product Designer and Entrepreneur from Dhaka, Bangladesh. Co-Founder of Symbl. Responsible AI and Machine Learning enthusiast.",
    url: "https://fahimmurshed.com",
    siteUrl: "https://fahimmurshed.com",
    banner: "/thumb.jpg",
    twitter: "@fahimmurshedo_o",
    facebook: "Fahim Murshed",
    siteLanguage: "en",
    ogLanguage: "en_US",
  },
  plugins: [
    "gatsby-plugin-catch-links",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-postcss",
    "gatsby-transformer-json",
    {
      resolve: "gatsby-plugin-prefetch-google-fonts",
      options: {
        fonts: [
          {
            family: "Source Sans Pro",
            variants: ["300", "400", "600", "700"],
          },
        ],
      },
    },
    "gatsby-plugin-sitemap",
    {
      resolve: "gatsby-plugin-disqus",
      options: {
        shortname: "fahim-murshed",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/blog-posts`,
        name: "blog-posts",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/content/showcase.json`,
        name: "showcase",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/content/mentions.json`,
        name: "mentions",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/content/pets.json`,
        name: "pets",
      },
    },
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "Fahim Murshed",
        short_name: "w/FahimMurshed",
        start_url: "/",
        background_color: "#fff",
        theme_color: "#E7305B",
        display: "standalone",
        icon: "static/fahimmurshed-icon.png",
        cache_busting_mode: "none",
      },
    },
    {
      resolve: "gatsby-plugin-offline",
      options: {
        workboxConfig: {
          globPatterns: ["**/*"],
        },
      },
    },
    {
      resolve: "gatsby-plugin-sharp",
      options: {
        // Available options and their defaults:
        defaultQuality: 75,
      },
    },
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          {
            resolve: "gatsby-remark-images",
            options: {
              maxWidth: 720,
              linkImagesToOriginal: false,
              loading: "lazy",
              disableBgImage: true,
            },
          },
          {
            resolve: "gatsby-remark-highlight-code",
          },
          "gatsby-remark-embed-youtube",
          "gatsby-remark-responsive-iframe",
        ],
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/static/`,
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: "UA-110318517-13",
      },
    },
  ],
}
